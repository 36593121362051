.container {
    display: flex;
    flex-direction: row;
}

.inboxList {
    width: 85%;
    max-width: calc(100vw - 150px);
}

.pagesSidebar {
    position: sticky;
    top: 60px;
    width: 15%;
    min-width: 240px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 60px)
}

.pageItem {
    border-bottom: 2px solid #959595;
}

.pageItem.active {
    border-right: 5px solid yellowgreen;
}


.logo {
    width: 24px;
    transform: translateY(5px);
}
.pageLabels{
    display: flex;
    gap: 10px;
    padding:0 0 12px 0;
    flex-wrap: wrap;
}

.inboxFilters {
    display: flex;
    gap: 10px;
    padding: 12px;
    flex-wrap: wrap;
}

.typeFilter {
    border: 2px solid #959595;
    padding: 6px;
    cursor: pointer;
}

.typeFilter.active {
    border-color: yellowgreen;
}

.labels strong {
    display: inline-block;
    font-size: 20px;
    transform: translateY(4px);

}

.labelListPreview {
    cursor: help;
    display: inline-block;
    left:0;
    position: absolute;

}

.assignedUser{
    color:yellowgreen;
    margin: 0 5px;
    padding: 2px;
    border: 1px solid white;
    cursor: pointer;
}