nav{
    position: sticky;
    top:0;
    background: #202428;
    text-align: center;
    box-shadow: 0px 9px 16px -7px rgba(0,0,0,0.75);
    z-index: 10;
}
.navLink{
    display: inline-block;
    padding: 10px 30px;
    font-size: 30px;
    margin: 0 20px;
    border: 3px solid #969696;
    color: white;
    text-decoration: none;
}
.navLink:hover{
    color:yellowgreen;
}

.navLink.active{
    border: 3px solid yellowgreen;
}