.creativesSummary {
    position: fixed;
    top: 60px;
    background: #0f5132;
    width: 100%;
    padding: 10px;
    font-size: 20px;
}

.creativesList {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.creativeItem {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.creativeData{
    white-space: pre-wrap;
}
.crMsg {
    padding-top: 10px;
    font-size: 12px;
}



.logo {
    width: 24px;
    transform: translateY(5px);
}

.abItem {
    display: flex;
    border: 2px solid darkcyan;
    padding: 5px;
    width: 240px;
    cursor: pointer;
}

.adItemName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fullAdInfoCover{
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
}
.fullAdInfo{
    background: black;
    width: 66%;
    padding: 10px;
    height: 50%;
    overflow-y: scroll;
}
.closeInfo{
    font-size: 30px;
    cursor: pointer;
    float:right;
}