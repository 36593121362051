.adminActionTitle {
    color: yellowgreen;
    border: 2px solid #959595;
}

.adminAction {
    border: 2px solid yellowgreen;
    padding: 10px;
}

.userActions {
    padding: 4px;
    border: 1px solid #959595;
    align-items: center;
}

.updateUser {
    display: flex;
    width: 50%;
    max-width: 420px;
    align-items: center;
}

.updateUser input {
    padding: 0;
    margin-bottom: 0;
}

.form {
    display: flex;
    margin-bottom: 10px;
}

.form div {
    width: 50%;
    padding: 0 10px;
    font-size: 21.5px;

}

.form input[type=text], .form input[type=email], .form input[type=password] {
    width: 100%;
}

.userForm input {
    height: 30px;
    margin-bottom: 12px;
}

.form select {
    width: 100%;
    padding: 6px;
}

.form button {
    width: fit-content;
    margin: 0;
}

.graphData {
    display: flex;
    padding: 6px 12px;
    border-bottom: 2px solid yellowgreen;
}

.graphData .usersPart > div {
    display: flex;
    align-items: center;
    margin-top: 30px;
    height: fit-content;
}

.pagesPart {

}

.graphData li {
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 10px;

}

.graphData li input[type=checkbox] {
    width: 30px;
    margin-top: 0;
}

.labelActions {

    display: flex;
}

td, th {
    border: 1px solid wheat;
    padding: 4px;
}

.mixedLabelExtraInfo {
    display: flex;
}