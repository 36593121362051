h3{
    margin: 12px 20px;
}
.graphUser{
    display: flex;
    align-items: center;
    padding:6px 12px;
}
.graphUser strong{
    margin: 10px;
}