.inboxItem {
    border-bottom: 2px solid #959595;

    padding: 4px;
}

.inboxItem.done {
    background: rgba(154, 205, 50, 0.21);
}

.timeSince {
    display: inline-block;
    float: right;
    background: #282c33;
}

.chatItem .itemContent {
    border-left: 2px solid #d86286;
    border-bottom-left-radius: 12px;
    padding: 4px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}

.chatItem .msgItem {
    padding-top: 12px;
}

.commentItem .itemContent {
    padding: 4px;
    margin-top: 10px;
    max-height: 760px;
    display: flex;
    flex-direction: row;
}

.commentItem .feedData {
    display: flex;
    white-space: pre-wrap;
    width: 50%;
    gap: 12px;
    padding-right: 12px;
    border-bottom-right-radius: 12px;
    border-right: 2px solid #d86286;
}

.commentItem .feedData img {
    white-space: pre-wrap;
    object-fit: contain;
    max-height: 200px;
    max-width: 50%;
    width: auto;
    height: auto;
}

h4 {
    margin: 0 0 8px 0
}

.commentInfo {
    padding: 0 12px;
}
.attachmentImg{
    object-fit: contain;
    max-height: 100px;
    width: auto;
    height: auto;
}
.username{
    color: yellowgreen;
}

.itemActions{
    display: flex;
}
.itemActions button{
    width: fit-content;
}

.emailItem strong {
    color: yellowgreen;
}

.emailItem .itemContent{

    white-space: pre-wrap;
}